import React, { useState, useEffect } from 'react';
import {  fetchTickerSuggestions, handleSearchStock, handleSuggestionClick } from '../helper/MarketsHelper';

export default function MarketSearchBar({ stock, setStock, isAuthenticated, userToken, navigate, marginVertical = '2rem' }) {
  const [suggestions, setSuggestions] = useState([]);
  const [activeMode, setActiveMode] = useState('stocks');

  const handleInputChange = (e) => {
    const query = e.target.value;
    setStock(query);
    debounceFetchSuggestions(query);
  }; 
  
  // Debounced function to fetch suggestions
  const debounce = (func, delay) => {
    let debounceTimer;
    return function (...args) {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(this, args), delay);
    };
  };

  useEffect(() => {
    setSuggestions([]);
    if (stock) {
      debounceFetchSuggestions(stock);
    }
  }, [activeMode]); 

  const debounceFetchSuggestions = (query) => debounce(fetchTickerSuggestions(query, setSuggestions, false, activeMode ), 500);

  return (
    <div className="MarketsSearchWrapper" style = {{marginTop: marginVertical, marginBottom: marginVertical}}>
      <input
        id="marketsSearchBar"
        className="MarketsSearchBar bold"
        type="text"
        placeholder="Search markets"
        value={stock}
        onChange={handleInputChange}
        onKeyDown={(e) => {handleSearchStock(e, stock, isAuthenticated, userToken, navigate, activeMode); if (e.key === 'Enter') setStock('');}}
      />
      {suggestions.length > 0 && stock && (
        <ul className="SuggestionsDropdown">
          {suggestions.map((suggestion) => (
            <li
              key={suggestion?.ticker}
              onClick={() =>{ handleSuggestionClick(suggestion?.ticker, isAuthenticated, userToken, navigate, activeMode); setStock('');}}
              className="SuggestionItem"
            >
              {suggestion?.ticker} - {suggestion?.name}
            </li>
          ))}
         
        </ul>
      )}
       <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
          <button
            className="CreatePostFeedButton"
            onClick={() => setActiveMode('stocks')}
            style={{ backgroundColor: activeMode === 'stocks' ? 'var(--stock-change-pos)' : 'var(--background-color-feed-btn)', color: activeMode === 'stocks' && '#000' }}
          >
            Stocks
          </button>
          <button
            className="CreatePostFeedButton"
            onClick={() => setActiveMode('crypto')}
            style={{ backgroundColor: activeMode === 'crypto' ? 'var(--stock-change-pos)' :  'var(--background-color-feed-btn)', color: activeMode === 'crypto' && '#000' }}
          >
            Crypto
          </button>
        </div>
    </div>
  );
}