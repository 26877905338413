import React, { useState, useEffect, useContext, useRef } from 'react';
import axios from 'axios';
import "../styles/Portfolio.css";
import { AuthContext } from '../context/AuthContext';
import {
  fetchAllTransactionsGeneral,
  fetchUserPortfolio,
  fetchUserCashBalance,
  getChangeColor,
  getChangePercentageColor,
  filters,
  getUnixTimestampRange,
  getUnixTimestamp,
  calculateReturns,
  fetchCompanyDetails,
  portfolioIntervalMap,
  isOption,
  handleNavigateToAsset,
  isCrypto,
  getTypeOfQuantity,
} from '../helper/MarketsHelper';
import { LoadingSpinner, formatCurrency as fc } from '../helper/StylesHelper';
import config from '../config';
import { createPortfolioChart } from '../helper/PortfolioChartHelper';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { ThemeContext } from '../context/ThemeContext';
import useResponsiveWidth from '../hooks/useResponsiveWidth';
import { shareTrade } from '../helper/CreatePostHelper';
import BackButton from './BackButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';

export default function Portfolio({inProfile = false}) {
  
  useResponsiveWidth();
  const [transactions, setTransactions] = useState([]);
  const [cashBalance, setCashBalance] = useState(null); 
  const [assetsValue, setAssetsValue] = useState(null); 

  const [allPortfolios, setAllPortfolios] = useState([]); 
  const [isCreatingPortfolio, setIsCreatingPortfolio] = useState(false);
  const [newPortfolioName, setNewPortfolioName] = useState('');

  const [selectedPortfolio, setSelectedPortfolio] = useState(null);
  const [transactionsLoading, setTransactionsLoading] = useState(true);
  const [filter, setFilter] = useState('1d');
  const [livePriceMap, setLivePriceMap] = useState(null);
  const [prevClosePriceMap, setPrevClosePriceMap] = useState({}); 
  const [quantityMap, setQuantityMap] = useState(null);
  const wsRef = useRef(null); 
  const navigate = useNavigate();

  const [referenceValue, setReferenceValue] = useState(null);

  const { userToken, isAuthenticated, username: currentUsername } = useContext(AuthContext);
  const {tc, theme} = useContext(ThemeContext);
  const { username } = useParams();
  const location = useLocation();

  const [portfolioData, setPortfolioData] = useState([]);
  const [dataFetched, setDataFetched] = useState(false);
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  const [hoveredPrice, setHoveredPrice] = useState(null);
  const [hoveredChangeAmount, setHoveredChangeAmount] = useState(null);
  const [hoveredChangePercent, setHoveredChangePercent] = useState(null);
  const [hoveredDateTime, setHoveredDateTime] = useState(null);
  const [isHovering, setIsHovering] = useState(false);
  const [tickerCompanyMap, setTickerCompanyMap] = useState({});
  const [balanceLoading, setBalanceLoading] = useState(true);
  const [tableLoading, setTableLoading] = useState(true);
  const [showAddCash, setShowAddCash] = useState(false);
  const [cashToAdd, setCashToAdd] = useState('');

  const [portfolioStats, setPortfolioStats] = useState({});
  const [portfolioChangeAmount, setPortfolioChangeAmount] = useState(null);
  const [portfolioChangePercentage, setPortfolioChangePercentage] = useState(null);

  // For sorting
  const [sortColumn, setSortColumn] = useState(null); // 'ticker', 'avgPrice', 'currentPrice', 'units', 'marketValue', 'unrealizedReturn', 'realizedReturn', 'oneDayPercent', 'buysSells'
  const [sortDirection, setSortDirection] = useState('asc'); // or 'desc'

  // For toggling columns in profile view
  const [showSecondColumnSet, setShowSecondColumnSet] = useState(false);

  useEffect(()=> {
    console.log('portfolios', allPortfolios);
  }, [allPortfolios]);

  const fetchAllPortfolios = async () => {  
    try {
      const response = await axios.get(`${config.backendUrl}/api/portfolios`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
        params: {
          username,
        },
      });

      setAllPortfolios(response.data);

      if (response.data && response.data.length > 0) {
        const mainPortfolio = response.data.find(portfolio => portfolio.isMainPortfolio);
        setSelectedPortfolio(mainPortfolio || response.data[0]);
      }
    } catch (error) {
      console.error('Error fetching portfolios:', error);
    }
  };

  useEffect(() => { 
    fetchAllPortfolios();
  }, [username, userToken, isAuthenticated]);

  const createNewPortfolio = async () => {  
    try {
      const response = await axios.post(
        `${config.backendUrl}/api/portfolios`,
        { name: newPortfolioName },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
  
      setAllPortfolios((prevPortfolios) => [...prevPortfolios, response.data]);
    } catch (error) {
      alert(error.response?.data?.message || 'An error occurred while creating the portfolio.');
      console.error('Error creating portfolio:', error);
    }
  };

  useEffect(() => {
    if (!selectedPortfolio || !selectedPortfolio._id) return;
    fetchUserPortfolio(isAuthenticated, userToken, setSelectedPortfolio, selectedPortfolio._id);
    fetchAllTransactionsGeneral(isAuthenticated, userToken, setTransactions, selectedPortfolio._id)
      .then(() => setTransactionsLoading(false));
    fetchUserCashBalance(isAuthenticated, userToken, setCashBalance, selectedPortfolio._id);
  }, [selectedPortfolio?._id, userToken, isAuthenticated]);

  useEffect(() => {
    if (!selectedPortfolio || !selectedPortfolio.assets) return;
    const tickerArray = selectedPortfolio.assets.map((stock) => stock.ticker);
  
    const fetchAllCompanyDetails = async () => {
      try {
        const companyDetailsPromises = tickerArray.map((ticker) =>
          fetchCompanyDetails(ticker)
        );
  
        const allCompanyDetails = await Promise.all(companyDetailsPromises);
  
        const tickerCompanyDetailsMap = allCompanyDetails.reduce((acc, companyInfo, index) => {
          const ticker = tickerArray[index];
          acc[ticker] = companyInfo;
          return acc;
        }, {});
  
        setTickerCompanyMap(tickerCompanyDetailsMap);
      } catch (error) {
        console.error("Error fetching company details for tickers:", error);
      }
    };

    fetchAllCompanyDetails();
  }, [selectedPortfolio]);

  useEffect(() => {
    if (!selectedPortfolio || !selectedPortfolio.assets || !livePriceMap || !tickerCompanyMap || !transactions) {
      return;
    }

    const buyMap = {};  
    const sellMap = {};
    const buyVolumeMap = {};  
    const sellVolumeMap = {};

    transactions.forEach((transaction) => {
      const { ticker, type, assetType } = transaction;
      const factor = (assetType === 'option' ? 100 : 1);
      if (type === 'buy') {
        buyMap[ticker] = (buyMap[ticker] || 0) + 1;
        buyVolumeMap[ticker] = (buyVolumeMap[ticker] || 0) + (transaction.quantity * transaction.price * factor);
      }
      if (type === 'sell') {
        sellMap[ticker] = (sellMap[ticker] || 0) + 1;
        sellVolumeMap[ticker] = (sellVolumeMap[ticker] || 0) + (transaction.quantity * transaction.price * factor);
      }
    });

    const statsTemp = {};
    selectedPortfolio.assets.forEach((stock) => {
      const ticker = stock.ticker.toUpperCase();
      const livePrice = livePriceMap[ticker];
      const tickerTransactions = transactions.filter((transaction) => transaction.ticker === ticker); 
      const { unrealizedDollar, unrealizedPercentage, realizedDollar, realizedPercentage } = calculateReturns(stock, livePrice, tickerTransactions);

      statsTemp[ticker] = {
        totalInvested: stock.quantity * stock.avgBuyPrice,
        marketValue: livePrice * stock.quantity,
        unrealizedDollar,
        unrealizedPercentage,
        realizedDollar,
        realizedPercentage,
        buyCount: buyMap[ticker] || 0,
        sellCount: sellMap[ticker] || 0,
        buyVolumeMap: buyVolumeMap[ticker] || 0,
        sellVolumeMap: sellVolumeMap[ticker] || 0,
      };
    });

    let totalInvested = Object.keys(statsTemp).reduce((total, ticker) => total + statsTemp[ticker].totalInvested, 0);
    let marketValue = Object.keys(statsTemp).reduce((total, ticker) => total + statsTemp[ticker].marketValue, 0);
    let totalRealizedDollar = Object.keys(statsTemp).reduce((total, ticker) => total + statsTemp[ticker].realizedDollar, 0);
    let totalUnrealizedDollar = Object.keys(statsTemp).reduce((total, ticker) => total + statsTemp[ticker].unrealizedDollar, 0);
  
    const initialCash = 100000; 
    const cashDeposited = selectedPortfolio?.cashDeposited || 0;
    const totalCash = initialCash + cashDeposited;
    let totalUnrealizedPercentage = totalCash > 0 ? (totalUnrealizedDollar / totalCash) * 100 : 0;
    let totalRealizedPercentage = totalCash > 0 ? (totalRealizedDollar / totalCash) * 100 : 0;

    let totalBuys = Object.keys(statsTemp).reduce((total, ticker) => total + statsTemp[ticker].buyCount, 0);
    let totalSells = Object.keys(statsTemp).reduce((total, ticker) => total + statsTemp[ticker].sellCount, 0);
    let totalBuyVolume = Object.keys(statsTemp).reduce((total, ticker) => total + statsTemp[ticker].buyVolumeMap, 0);
    let totalSellVolume = Object.keys(statsTemp).reduce((total, ticker) => total + statsTemp[ticker].sellVolumeMap, 0);

    setPortfolioStats({
      totalInvested,
      marketValue,
      totalUnrealizedPercentage,
      totalUnrealizedDollar,
      totalRealizedDollar,
      totalRealizedPercentage,
      totalBuys,
      totalSells,
      totalBuyVolume,
      totalSellVolume,
      ...statsTemp,
    });

  }, [selectedPortfolio, livePriceMap, transactions, tickerCompanyMap]);

  useEffect(() => {
    if (portfolioStats && tickerCompanyMap ) {
      setTableLoading(false);
    }
  }, [portfolioStats, tickerCompanyMap]);

  const handleConfirmAddCash = async () => {
    try {
      const amount = parseFloat(cashToAdd);
      if (isNaN(amount) || amount <= 0) {
        alert('Please enter a valid amount greater than 0.');
        return;
      }
  
      const response = await axios.post(
        `${config.backendUrl}/api/stockActions/addCash`,
        { amount, portfolioId: selectedPortfolio._id },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
  
      setCashBalance(response.data.cash);
      setShowAddCash(false);
      setCashToAdd('');

      alert(response.data.message);
      fetchAllTransactionsGeneral(isAuthenticated, userToken, setTransactions, selectedPortfolio._id);
      fetchUserPortfolio(isAuthenticated, userToken, setSelectedPortfolio, selectedPortfolio._id);
    } catch (error) {
      console.error('Error adding cash:', error);
      alert(error.response?.data?.message || 'An error occurred while adding cash.');
    }
  };
  
  const handleCancelAddCash = () => {
    setShowAddCash(false);
    setCashToAdd('');
  };

  const fetchPortfolioDataPoints = async () => {
    if (!selectedPortfolio || !selectedPortfolio._id) return;

    setDataFetched(false);
    try {
      let fromDateUnixMs;
      let toDateUnixMs = getUnixTimestamp();

      if (filter === 'all time') {
        fromDateUnixMs = new Date('2024-08-01').getTime();
      } else {
        fromDateUnixMs = getUnixTimestampRange(filter);
      }

      const intervalArray = portfolioIntervalMap[filter];
      const multiplier = intervalArray[0];
      const timespan = intervalArray[1];
       
      const response = await axios.get(`${config.backendUrl}/api/markets/portfolio/datapoints/${username}/${selectedPortfolio._id}`, {
        params: {
          multiplier,
          timespan,
          fromDateUnixMs,
          toDateUnixMs,
        },
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      setReferenceValue(response.data[0]?.portfolioValue);
      setPortfolioData(response.data);
     
    } catch (error) {
      setPortfolioData([]);
      setPortfolioChangeAmount("--");
      setPortfolioChangePercentage("--");
      console.error('Error fetching portfolio data points:', error);
    }
    setDataFetched(true);
  };

  useEffect(() => {
    if (userToken && selectedPortfolio && selectedPortfolio._id) {
      fetchPortfolioDataPoints();
    }
  }, [userToken, filter, cashBalance, selectedPortfolio?._id, isAuthenticated]);

  useEffect(()=> {
    console.log('selectedPortfolio:', selectedPortfolio);
  }, [selectedPortfolio]);

  useEffect(() => {
    if (!chartRef.current || !dataFetched || !portfolioData.length) return;

    const referenceValueForChart = portfolioData[0]?.portfolioValue;
    setReferenceValue(referenceValueForChart);

    const cleanup = createPortfolioChart({
      chartRef,
      chartInstanceRef: chartInstance,
      portfolioData,
      referenceValueForChart,
      setHoveredPrice,
      setHoveredChangeAmount,
      setHoveredChangePercent,
      setHoveredDateTime,
      setIsHovering,
      theme,
    });

    return cleanup;
  }, [portfolioData, dataFetched, filter, theme, cashBalance]);

  useEffect(() => {
    if (!selectedPortfolio || !selectedPortfolio.assets) return;

    const ws = new WebSocket(config.socketUrl);

    ws.onopen = () => {
      selectedPortfolio.assets.forEach((stock) => {
        const ticker = stock.ticker.toUpperCase();
        ws.send(JSON.stringify({ action: 'subscribe', ticker }));
      });
    };

    ws.onmessage = (event) => {
      const message = JSON.parse(event.data);
      if (message.type === 'instantPrice') {
        message.data.forEach((data) => {
          if (data.ev === 'T') {
            const ticker = data.sym;
            let livePrice = parseFloat(data.p);
            if (isOption(ticker)) {
              livePrice = livePrice * 100;
            }
            setLivePriceMap((prevMap) => ({
              ...prevMap,
              [ticker]: livePrice,
            }));
          }
        });
      }
    };

    ws.onclose = () => {
      console.log('WebSocket connection closed');
    };

    ws.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    wsRef.current = ws;

    return () => {
      if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
        selectedPortfolio.assets.forEach((stock) => {
          const ticker = stock.ticker.toUpperCase();
          wsRef.current.send(JSON.stringify({ action: 'unsubscribe', ticker }));
        });
        wsRef.current.close();
      }
    };
  }, [selectedPortfolio]);

  useEffect(() => {
    if (!selectedPortfolio || !selectedPortfolio.assets) return;

    const tickers = selectedPortfolio.assets.map((stock) => stock.ticker.toUpperCase());
    const fetchPrice = () => {
      if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
        tickers.forEach((ticker) => {
          wsRef.current.send(JSON.stringify({ action: 'getInstantPrice', ticker }));
        });
      }
    };

    const intervalId = setInterval(fetchPrice, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [selectedPortfolio]);

  useEffect(() => {
    if (!selectedPortfolio) return;

    const fetchCurrentPrices = async () => {
      try {
        const pricePromises = selectedPortfolio.assets.map((stock) => {
          const ticker = stock.ticker.toUpperCase();
          return axios
            .get(`${config.backendUrl}/api/markets/dailychange?ticker=${ticker}`)
            .then((response) => {
              let { currentPrice, prevClosePrice } = response.data;
              if (isOption(ticker)) {
                currentPrice = currentPrice * 100;
                prevClosePrice = prevClosePrice * 100;
              }
              return { ticker, currentPrice, prevClosePrice };
            })
            .catch((error) => {
              console.error(`Error fetching price for ${ticker}:`, error);
              return { ticker, currentPrice: '--', prevClosePrice: undefined };
            });
        });

        const prices = await Promise.all(pricePromises);
        const updatedPriceMap = {};
        const updatedPrevCloseMap = {};

        prices.forEach(({ticker, currentPrice, prevClosePrice}) => {
          updatedPriceMap[ticker] = currentPrice;
          if (prevClosePrice !== undefined) {
            updatedPrevCloseMap[ticker] = prevClosePrice;
          }
        });

        setLivePriceMap((prevMap) => ({ ...prevMap, ...updatedPriceMap }));
        setPrevClosePriceMap((prevMap) => ({ ...prevMap, ...updatedPrevCloseMap }));
      } catch (error) {
        console.error('Error fetching current prices:', error);
      }
    };

    fetchCurrentPrices();
  }, [selectedPortfolio]);

  useEffect(() => {
    if (!selectedPortfolio || !selectedPortfolio.assets) return;

    const quantityMapTemp = selectedPortfolio.assets.reduce((map, stock) => {
      map[stock.ticker.toUpperCase()] = stock.quantity;
      return map;
    }, {});

    setQuantityMap(quantityMapTemp);
  }, [selectedPortfolio]);

  useEffect(() => {
    if (!livePriceMap || !quantityMap) {
      return;
    }

    const liveValueMapTemp = Object.keys(livePriceMap).reduce((map, ticker) => {
      const price = parseFloat(livePriceMap[ticker]);
      const quantity = quantityMap[ticker];
      if (price && quantity) {
        map[ticker] = price * quantity;
      }
      return map;
    }, {});
  
    const assetsValueTemp = Object.values(liveValueMapTemp).reduce((total, value) => total + value, 0);
    setAssetsValue(assetsValueTemp);
  }, [livePriceMap, quantityMap]);

  useEffect(() => {
    if (cashBalance !== null && assetsValue !== null) {
      setBalanceLoading(false);
    }
  }, [cashBalance, assetsValue]);

  useEffect(()=> {
    if (!dataFetched) {
      setPortfolioChangeAmount("--");
      setPortfolioChangePercentage("--");
    } else if (typeof cashBalance === 'number' && typeof assetsValue === 'number' && typeof referenceValue === 'number' && portfolioData.length > 0) {
      const changeAmount = (cashBalance + assetsValue) - referenceValue;
      const changePercentage = (changeAmount / referenceValue) * 100;
      setPortfolioChangeAmount(changeAmount);
      setPortfolioChangePercentage(changePercentage);
    } 
  }, [cashBalance, assetsValue, referenceValue, portfolioData, dataFetched]);

  const renderTradeCard = (transaction) => {  
    if (transactionsLoading || !livePriceMap || !tickerCompanyMap) {
      return <LoadingSpinner />;
    }
  
    let { ticker, type, price , date, quantity, _id} = transaction;
    let originalPrice = price;
    if (isOption(ticker)) {
      price = price * 100;
    }

    const formattedDate = new Date(date).toLocaleString();
    const livePrice = parseFloat(livePriceMap[ticker]);
    const liveChangeAmount = (typeof livePrice === 'number' && typeof price === 'number') ? livePrice - price : null;
    const liveChangePercentage = (typeof livePrice === 'number' && typeof price === 'number') ? ((livePrice - price) / price) * 100 : null;
    const isBuy = type === 'buy';
    let changePercentageToUse = liveChangePercentage || 0;

    return (
      <div className='PortTrade' 
        onClick={()=> shareTrade(_id, navigate)}
        style={{
          backgroundColor: isBuy ? getChangeColor(changePercentageToUse, tc()) : 'transparent',
          border: !isBuy && `0.05rem solid ${getChangePercentageColor(-changePercentageToUse, tc())}`,
          width: inProfile && 'auto',
        }}
      >
        <div className='PortTradeHeader'>
          <span className='PortTradeLabel'>
            {`${type === 'buy' ? 'BUY' : 'SELL'} - ${formattedDate}`}
          </span>
          <div className='PortTradeCompanyHeader'>
            <span className='PortTradeLabel'>
              {ticker}
            </span>
            <span className="PortTradeValue bold">
              {tickerCompanyMap[ticker]?.name}
            </span>
          </div>
          <div className="PortTradeTable" style={{
            width: inProfile && '100%',
             gridTemplateColumns: inProfile && 'fit-content(100%) fit-content(100%) 8rem fit-content(100%) fit-content(100%)'}}>
            <div className="PortTradeTableRow">
              <span className='PortTradeLabel'>Price:</span>
              <span className="PortTradeValue bold">${fc(originalPrice)}</span>
              <span></span>
              <span className="PortTradeLabel">Since {isBuy ? 'buy' : 'sale'}:</span>
              <span style = {{color: getChangePercentageColor(liveChangeAmount, tc())}}
                className="PortTradeValue bold">
                {liveChangeAmount >=0 && '+'}{fc(liveChangeAmount * quantity)}
              </span>
            </div>
            <div className="PortTradeTableRow">
              <span className='PortTradeLabel'>{getTypeOfQuantity(ticker)}: </span>
              <span className="PortTradeValue bold">{fc(quantity, isCrypto(ticker) ? 4 : 2)}</span>
              <span></span>
              <span className="PortTradeLabel">Since {isBuy ? 'buy' : 'sale'}:</span>
              <span style = {{color:getChangePercentageColor(liveChangeAmount, tc())}}
                className="PortTradeValue bold">
                {liveChangePercentage >= 0 && '+'}{fc(liveChangePercentage)}%
              </span>
            </div>
            <div className="PortTradeTableRow">
              <span className='PortTradeLabel'>Total:</span>
              <span className="PortTradeValue bold">${fc(price * quantity)}</span>
              <span></span>
              <span className="PortTradeLabel">Present Value:</span>
              <span className="PortTradeValue bold">${fc(livePrice * quantity)}</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  // Sorting Helpers
  const calculateOneDayChange = (currentPrice, prevClosePrice) => {
    if (!currentPrice || !prevClosePrice || prevClosePrice === 0) return NaN;
    const change = ((currentPrice - prevClosePrice) / prevClosePrice) * 100;
    return isNaN(change) ? NaN : change;
  };

  const applySorting = (rowsData) => {
    if (!sortColumn) return rowsData;

    const compare = (a, b) => {
      let valA, valB;
      switch (sortColumn) {
        case 'ticker':
          valA = a.ticker;
          valB = b.ticker;
          return valA.localeCompare(valB);
        case 'avgPrice':
          valA = a.avgPrice;
          valB = b.avgPrice;
          return valA - valB;
        case 'currentPrice':
          valA = a.currentPrice;
          valB = b.currentPrice;
          return valA - valB;
        case 'units':
          valA = a.quantity;
          valB = b.quantity;
          return valA - valB;
        case 'marketValue':
          valA = a.marketValue;
          valB = b.marketValue;
          return valA - valB;
        case 'unrealizedReturn':
          valA = a.unrealizedDollar;
          valB = b.unrealizedDollar;
          return valA - valB;
        case 'realizedReturn':
          valA = a.realizedDollar;
          valB = b.realizedDollar;
          return valA - valB;
        case 'oneDayPercent':
          valA = a.oneDayChange;
          valB = b.oneDayChange;
          return valA - valB;
        case 'buysSells':
          valA = a.buyCount + a.sellCount;
          valB = b.buyCount + b.sellCount;
          return valA - valB;
        default:
          return 0;
      }
    };

    const sorted = [...rowsData].sort(compare);
    if (sortDirection === 'desc') {
      sorted.reverse();
    }
    return sorted;
  };

  const handleSort = (column) => {
    if (sortColumn === column) {
      // Toggle between ascending, descending, and no sorting
      if (sortDirection === 'asc') {
        setSortDirection('desc');
      } else if (sortDirection === 'desc') {
        setSortColumn(null); // Reset sorting
        setSortDirection('asc'); // Default direction for next click
      }
    } else {
      setSortColumn(column);
      setSortDirection('asc'); // Start with ascending when a new column is clicked
    }
  };

  const renderTable = () => { 
    if (transactions?.length === 0) {
      return;
    }
  
    if (tableLoading || !livePriceMap || !tickerCompanyMap) {
      return <LoadingSpinner />;
    }
  
    const keysToUse = Object.keys(portfolioStats).filter((ticker) => {
      return ticker !== 'totalInvested' && ticker !== 'marketValue'
        && ticker !== 'totalUnrealizedPercentage' && ticker !== 'totalUnrealizedDollar'
        && ticker !== 'totalRealizedDollar' && ticker !== 'totalRealizedPercentage'
        && ticker !== 'totalBuys' && ticker !== 'totalSells' && ticker !== 'totalBuyVolume' && ticker !== 'totalSellVolume';
    });
  
    const rowsData = keysToUse.map((ticker) => {
      const currentPrice = livePriceMap[ticker];
      const prevClosePrice = prevClosePriceMap[ticker];
      const quantity = quantityMap[ticker];
      const oneDayChange = calculateOneDayChange(currentPrice, prevClosePrice);
      const avgPrice = selectedPortfolio.assets.find((stock) => stock.ticker === ticker)?.avgBuyPrice || 0;
      return {
        ticker,
        name: tickerCompanyMap[ticker]?.name || "N/A",
        avgPrice,
        currentPrice,
        quantity,
        marketValue: portfolioStats[ticker]?.marketValue || 0,
        unrealizedDollar: portfolioStats[ticker]?.unrealizedDollar || 0,
        unrealizedPercentage: portfolioStats[ticker]?.unrealizedPercentage || 0,
        realizedDollar: portfolioStats[ticker]?.realizedDollar || 0,
        realizedPercentage: portfolioStats[ticker]?.realizedPercentage || 0,
        oneDayChange,
        buyCount: portfolioStats[ticker]?.buyCount || 0,
        sellCount: portfolioStats[ticker]?.sellCount || 0,
      };
    });
  
    const sortedRows = applySorting(rowsData);
  
    const renderSortIcon = (column) => {
      if (sortColumn === column) {
        return sortDirection === 'asc' ? '▲' : '▼';
      }
      return '';
    };
  
    // Determine which columns to show based on `inProfile` and `showSecondColumnSet`
    const showSet1 = !inProfile || !showSecondColumnSet; // Show all if not in profile
    const showSet2 = !inProfile || showSecondColumnSet;
  
    return (
      <>
        <div className="port-stats-table">
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <h2 className="port-right-sidebar-header" style={{ margin: 0 }}>Holdings</h2>
            {inProfile && (
              <div className='mv2-pagination-arrows'>
                {/* Left arrow: go back to set 1 */}
                <button
                  className='mv2-pagination-button'
                  onClick={() => setShowSecondColumnSet(false)}
                  disabled={!showSecondColumnSet}
                >
                  <FontAwesomeIcon
                    icon={faArrowLeft}
                    style={{
                      fontSize: '2rem',
                      color: !showSecondColumnSet ? 'var(--border-color)' : 'var(--text-color)',
                    }}
                  />
                </button>
                
                {/* Right arrow: go to set 2 */}
                <button
                  className='mv2-pagination-button'
                  onClick={() => setShowSecondColumnSet(true)}
                  disabled={showSecondColumnSet}
                >
                  <FontAwesomeIcon
                    icon={faArrowRight}
                    style={{
                      fontSize: '2rem',
                      color: showSecondColumnSet ? 'var(--border-color)' : 'var(--text-color)'
                    }}
                  />
                </button>
              </div>
            )}
          </div>
          <table className="stats-table">
            <thead>
              <tr className='port-stats-row'>
                <th onClick={() => handleSort('ticker')}>
                  Ticker/<br />Name {renderSortIcon('ticker')}
                </th>
                {showSet1 && (
                  <>
                    <th onClick={() => handleSort('avgPrice')}>
                      Average Price {renderSortIcon('avgPrice')}
                    </th>
                    <th onClick={() => handleSort('currentPrice')}>
                      Current Price {renderSortIcon('currentPrice')}
                    </th>
                    <th onClick={() => handleSort('units')}>
                      Units held {renderSortIcon('units')}
                    </th>
                    <th onClick={() => handleSort('marketValue')}>
                      Market Value/<br />% portfolio {renderSortIcon('marketValue')}
                    </th>
                  </>
                )}
                {showSet2 && (
                  <>
                    <th onClick={() => handleSort('unrealizedReturn')}>
                      Unrealized Return {renderSortIcon('unrealizedReturn')}
                    </th>
                    <th onClick={() => handleSort('realizedReturn')}>
                      Realized Return {renderSortIcon('realizedReturn')}
                    </th>
                    <th onClick={() => handleSort('oneDayPercent')}>
                      1d % {renderSortIcon('oneDayPercent')}
                    </th>
                    <th onClick={() => handleSort('buysSells')}>
                      Buys/<br />Sells {renderSortIcon('buysSells')}
                    </th>
                  </>
                )}
              </tr>
            </thead>
            <tbody>
              {sortedRows.map((row) => {
                const ticker = row.ticker;
                const change1d = row.oneDayChange;
                const investPercentage = fc(portfolioStats[ticker].totalInvested / portfolioStats.totalInvested * 100) || "--";
                return (
                  <tr 
                    className='port-stats-row'
                    onClick={() => handleNavigateToAsset(navigate, ticker)}
                    key={ticker}
                  >
                    <td className='bold'>
                      {ticker}
                      <br />
                      <span className="bold port-stats-company">
                        {row.name}
                      </span>
                    </td>
                    {showSet1 && (
                      <>
                        <td className='bold'>${fc(row.avgPrice) || "--"}</td>
                        <td className='bold'>${fc(row.currentPrice) || "--"}</td>
                        <td className='bold'>{fc(row.quantity)}</td>
                        <td className='bold'>
                          ${fc(row.marketValue) || "--"}
                          <br />
                          <span className="bold port-stats-company">
                            {investPercentage}%
                          </span>
                        </td>
                      </>
                    )}
                    {showSet2 && (
                      <>
                        <td
                          className={
                            row.unrealizedPercentage >= 0
                              ? "port-positive bold"
                              : "port-negative bold"
                          }
                        >
                          {row.unrealizedDollar >= 0 ? '+' : ''}
                          {fc(row.unrealizedDollar)}
                          <br />
                          ({fc(row.unrealizedPercentage, 2, true)}%)
                        </td>
                        <td
                          className={
                            row.realizedPercentage >= 0
                              ? "port-positive bold"
                              : "port-negative bold"
                          }
                        >
                          {row.realizedDollar >= 0 ? '+' : ''}
                          {fc(row.realizedDollar)}
                          <br />
                          ({fc(row.realizedPercentage, 2, true)}%)
                        </td>
                        <td className='bold' style={{ color: getChangePercentageColor(change1d, tc()) }}>
                          {!isNaN(change1d) && change1d >= 0 && '+'}{isNaN(change1d) ? '--' : `${fc(change1d, 2)}%`}
                        </td>
                        <td className='bold'>
                          {row.buyCount !== undefined ? fc(row.buyCount, 0) : "--"} / {row.sellCount !== undefined ? fc(row.sellCount, 0) : "--"}
                        </td>
                      </>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </>
    );
  };

  const renderTradeHistory = (transaction) => {
    if (transactionsLoading || !livePriceMap) {
      return <LoadingSpinner />;
    }
    return renderTradeCard(transaction);
  };


  const renderAccountStats = () => {
    const gridTemplateColumns = inProfile ? "1fr 1fr" : "1fr 1fr 1fr 1fr";
    
    // Calculate Cash-to-Investment Ratio
    const cashToInvestmentRatio = portfolioStats?.marketValue > 0 
      ? (cashBalance / portfolioStats.marketValue).toFixed(2) 
      : '--';
    
    return (
      <div className="account-stats-container">
        <h2 className="port-right-sidebar-header">Account Stats</h2>
        <div className="account-stats-grid" style={{ gridTemplateColumns }}>
          {/* Financial Balances Group */}
          <div className="account-stat-row">
            <span>Cash</span>
            <span className="bold">{cashBalance !== null ? `$${fc(cashBalance)}` : '--'}</span>
          </div>
          <div className="account-stat-row">
            <span>Market Value</span>
            <span className="bold">{portfolioStats?.marketValue ? `$${fc(portfolioStats.marketValue)}` : '--'}</span>
          </div>
  
          {/* Investment Totals Group */}
          <div className="account-stat-row">
            <span>Total Invested</span>
            <span className="bold">{portfolioStats?.totalInvested ? `$${fc(portfolioStats.totalInvested)}` : '--'}</span>
          </div>
          <div className="account-stat-row">
            <span>Cash-to-Investment Ratio</span>
            <span className="bold">{cashToInvestmentRatio}</span>
          </div>
  
          {/* Returns Group */}
          <div className="account-stat-row">
            <span>Unrealized Returns</span>
            <span
              style={{
                color: portfolioStats?.totalUnrealizedPercentage >= 0
                  ? 'var(--stock-change-pos)'
                  : 'var(--stock-change-neg)',
              }}
              className="bold"
            >
              {portfolioStats?.totalUnrealizedDollar !== undefined ? (
                <>
                  {portfolioStats?.totalUnrealizedDollar >= 0 ? '+' : ''}
                  {fc(portfolioStats?.totalUnrealizedDollar)} ({fc(portfolioStats?.totalUnrealizedPercentage, 2, true)}%)
                </>
              ) : (
                '--'
              )}
            </span>
          </div>
          <div className="account-stat-row">
            <span>Realized Returns</span>
            <span
              style={{
                color: portfolioStats?.totalRealizedPercentage >= 0
                  ? 'var(--stock-change-pos)'
                  : 'var(--stock-change-neg)',
              }}
              className="bold"
            >
              {portfolioStats?.totalRealizedDollar !== undefined ? (
                <>
                  {portfolioStats?.totalRealizedDollar >= 0 ? '+' : ''}
                  {fc(portfolioStats?.totalRealizedDollar)} ({fc(portfolioStats?.totalRealizedPercentage, 2, true)}%)
                </>
              ) : (
                '--'
              )}
            </span>
          </div>
  
          {/* Transactions Group */}
          <div className="account-stat-row">
            <span>Trades</span>
            <span className="bold">{transactions?.length !== undefined ? transactions.length : '--'}</span>
          </div>
          <div className="account-stat-row">
            <span>Buys</span>
            <span className="bold">{portfolioStats.totalBuys !== undefined ? fc(portfolioStats.totalBuys, 0) : "--"}</span>
          </div>
  
          {/* Transaction Volumes Group */}
          <div className="account-stat-row">
            <span>Buy Volume</span>
            <span className="bold">{portfolioStats?.totalBuyVolume !== undefined ? `$${fc(portfolioStats.totalBuyVolume)}` : '--'}</span>
          </div>
          <div className="account-stat-row">
            <span>Sells</span>
            <span className="bold">{portfolioStats.totalSells !== undefined ? fc(portfolioStats.totalSells, 0) : "--"}</span>
          </div>
  
          {/* Transaction Volumes (continued) */}
          <div className="account-stat-row">
            <span>Sell Volume</span>
            <span className="bold">{portfolioStats?.totalSellVolume !== undefined ? `$${fc(portfolioStats.totalSellVolume)}` : '--'}</span>
          </div>
  
          {/* Portfolio Metadata */}
          <div className="account-stat-row">
            <span>Portfolio Created</span>
            <span className="bold">{selectedPortfolio?.createdAt ? new Date(selectedPortfolio.createdAt).toLocaleDateString() : '--'}</span>
          </div>
        </div>
      </div>
    );
  };
  

  const renderPortfolioBreakdown = () => {
    const keysToUse = Object.keys(portfolioStats).filter((ticker) => {
      return ticker !== 'totalInvested' && ticker !== 'marketValue'
        && ticker !== 'totalUnrealizedPercentage' && ticker !== 'totalUnrealizedDollar'
        && ticker !== 'totalRealizedDollar' && ticker !== 'totalRealizedPercentage'
        && ticker !== 'totalBuys' && ticker !== 'totalSells' && ticker !== 'totalBuyVolume' && ticker !== 'totalSellVolume';
    });
  
    const totalValue = portfolioStats.marketValue || 0;
    if (totalValue === 0) return null;
  
    const industryMap = {};
  
    keysToUse.forEach((ticker) => {
      const industry = tickerCompanyMap[ticker]?.industry || "Unknown";
      const mv = portfolioStats[ticker]?.marketValue || 0;
      if (!industryMap[industry]) {
        industryMap[industry] = 0;
      }
      industryMap[industry] += mv;
    });
  
    const industryEntries = Object.entries(industryMap);
    industryEntries.sort((a, b) => b[1] - a[1]);
  
    const predefinedColors = ['#00FF00', '#00D4FF', '#FFE500', '#FF00D4', '#FF4D00', '#0900FF'];
    const usedColors = new Set();
  
    const segments = industryEntries.map((entry, index) => {
      const [industry, value] = entry;
      const percentage = (value / totalValue) * 100;
  
      let color = predefinedColors[index % predefinedColors.length];
      if (usedColors.has(color)) {
        color = `#${Math.floor(Math.random() * 16777215).toString(16).padStart(6, '0')}`;
      }
      usedColors.add(color);
  
      return { industry, value, percentage, color };
    });
  
    return (
      <div className="portfolio-breakdown-container">
        <h2 className="port-right-sidebar-header">Portfolio Breakdown</h2>
        <div className="portfolio-breakdown-bar">
          {segments.map((segment, idx) => (
            <div
              key={idx}
              className="portfolio-breakdown-segment"
              style={{
                width: `${segment.percentage}%`,
                backgroundColor: segment.color,
              }}
            />
          ))}
        </div>
        <div className="portfolio-breakdown-legend">
          {segments.map((segment, idx) => (
            <div key={idx} className="portfolio-breakdown-legend-item">
              <span
                className="portfolio-breakdown-legend-color"
                style={{ backgroundColor: segment.color }}
              ></span>
              <span className="portfolio-breakdown-legend-text">
                {segment.industry} ({fc(segment.percentage, 2, true)}%)
              </span>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderPortfolio = () => {
    if (!selectedPortfolio || balanceLoading || portfolioChangeAmount === null || portfolioChangePercentage === null) {
      return <LoadingSpinner />;
    }
  
    const displayChangeAmount = isHovering
      ? parseFloat(hoveredChangeAmount)
      : portfolioChangeAmount;
    const displayChangePercentage = isHovering
      ? parseFloat(hoveredChangePercent)
      : portfolioChangePercentage;
  
    const displayValue = (dataFetched && typeof cashBalance === 'number' && typeof assetsValue === 'number')
      ? `$${fc(cashBalance + assetsValue)}`
      : '--';
  
    const handleSetAsMainPortfolio = async () => {
      try {
        await axios.put(
          `${config.backendUrl}/api/portfolios/edit-mainPortfolio`,
          { newMainPortfolioId: selectedPortfolio._id },
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          }
        );
        await fetchAllPortfolios();
        alert(`Main portfolio set to "${selectedPortfolio.name}"`);
      } catch (error) {
        console.error('Error setting main portfolio:', error);
        alert(error.response?.data?.message || 'An error occurred while setting the main portfolio.');
      }
    };
  
    return (
      <div className="port-header-wrapper">
        <div className="port-cards-wrapper">
          {allPortfolios.map((portfolio, index) => (
            <div
              key={index}
              onClick={() => setSelectedPortfolio(portfolio)}
              className={`port-card bold ${selectedPortfolio?._id === portfolio?._id ? 'port-card-selected' : ''}`}
            >
              <span className='bold'>
                {portfolio.name}{' '}
                {portfolio.isMainPortfolio && (
                  <span
                    className={`port-card-main-span ${
                      selectedPortfolio?._id === portfolio?._id ? 'port-card-main-span-selected' : ''
                    }`}
                  >
                    Main
                  </span>
                )}
              </span>
            </div>
          ))}
  
          {currentUsername === username && (isCreatingPortfolio ? (
            <div className="port-card-edit-wrapper">
              <div className='port-card-edit'>
                <input
                  type="text"
                  value={newPortfolioName}
                  onChange={(e) => setNewPortfolioName(e.target.value)}
                  placeholder="Portfolio Name"
                  className='AddCashInput'
                />
                <button
                  className='CreatePostFeedButton'
                  onClick={async () => {
                    if (!newPortfolioName.trim()) {
                      alert('Please enter a portfolio name');
                      return;
                    }
                    await createNewPortfolio(newPortfolioName);
                    setIsCreatingPortfolio(false);
                    setNewPortfolioName('');
                    fetchAllPortfolios();
                  }}
                >
                  Save
                </button>
                <button
                  className='CreatePostFeedButton'
                  onClick={() => {
                    setIsCreatingPortfolio(false);
                    setNewPortfolioName('');
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          ) : (
            currentUsername === username &&
            <div
              className="port-card bold"
              onClick={() => setIsCreatingPortfolio(true)}
            >
              New portfolio +
            </div>
          ))}
        </div>
        <span className='port-selected-name bold'>
          {selectedPortfolio?.name}
        </span>
        <span className="port-value bold">
          {isHovering ? `$${fc(hoveredPrice)}`: displayValue}
        </span>
        <span
          className={`StockChange bold ${
            !isNaN(displayChangeAmount) && displayChangeAmount >= 0
              ? 'port-positive'
              : 'port-negative'
          }`}
          style={{ textAlign: 'left' }}
        >
          {!isNaN(displayChangeAmount) ? (
            <>
              {displayChangeAmount >= 0 ? '+' : ''}
              {fc(displayChangeAmount)} (
              {fc(displayChangePercentage, 2, true)}%)
            </>
          ) : (
            <span style={{ color: 'var(--action-grey)' }}>--</span>
          )}
  
          <span className="StockDuration"> {isHovering ? hoveredDateTime : filters[filter] === 'live'
              ? ' live'
              : filters[filter] === 'all time'
              ? ' all time'
              : ` past ${filters[filter]}`}
          </span>
        </span>
        {showAddCash ? (
          <div className="AddCashContainer">
            <input
              type="number"
              value={cashToAdd}
              onChange={(e) => setCashToAdd(e.target.value)}
              placeholder="Enter amount"
              className="AddCashInput"
            />
            <button onClick={handleConfirmAddCash} className="CreatePostFeedButton greenButton">Confirm</button>
            <button onClick={handleCancelAddCash} className="CreatePostFeedButton">Cancel</button>
          </div>
        ) : currentUsername === username && selectedPortfolio && (
          <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center', marginTop: '0.5rem', marginBottom: '2rem' }}>
            <button
              onClick={() => setShowAddCash(true)}
              className='CreatePostFeedButton greenButton'
              style={{ width: 'fit-content' }}
            >
              Add Cash
            </button>

            {!selectedPortfolio.isMainPortfolio && (
              <button
                onClick={handleSetAsMainPortfolio}
                className='CreatePostFeedButton'
                style={{ width: 'fit-content' }}
              >
                Set as main
              </button>
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="port-container" style = {{paddingTop: location.state?.fromProfile && '0', padding: inProfile && '0'}}>
  
      {location.state?.fromProfile && <BackButton padding={"2rem 0"} callback={()=> window.history.back()}/>}
      <div className="port-main-content">
        {renderPortfolio()}
        {!dataFetched ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
             height: 'var(--stock-chart-height)'
            }}
          >
            <LoadingSpinner />
          </div>
        ) : (
          <div style={{ height: 'var(--stock-chart-height)'}}>
            <canvas ref={chartRef}></canvas>
          </div>
        )}
        <div className="StockFilters" style = {{justifyContent: inProfile && 'space-between', gap: inProfile && '0rem'}}>
          {Object.keys(filters).map((key) => (
            <button
              key={key}
              className={`stock-filter-btn ${filter === key ? 'selected' : 'not-selected'}`}
              onClick={() => setFilter(key)}
            >
              {key}
            </button>
          ))}
        </div>
        <div className="port-stats-filter-section">
  {balanceLoading ? (
    <LoadingSpinner />
  ) : (
    <>
     
      {renderTable()}
    </>
  )}
</div>
      </div>
      
      {renderAccountStats()}
      {renderPortfolioBreakdown()}

      <div className="port-right-sidebar">
        <h2 style = {{marginBottom: 'calc(0.83rem + 1rem)'}}className="port-right-sidebar-header">Transaction history</h2>
        <div className="port-trade-history" style = {{flexDirection: inProfile && 'column'}}>
          {transactionsLoading ?  <LoadingSpinner /> :
          transactions.length === 0 ? (
              <span style = {{textAlign: 'left'}}className="ov-trade-type bold ov-15">No trades</span>
          ) :
          transactions.map((transaction) => renderTradeHistory(transaction))
          }
        </div>
      </div>
    </div>
  );
}