import React, { useState, useEffect, useContext, useRef } from 'react';
import Cropper from 'react-easy-crop';
import '../styles/Profile.css';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import config from '../config';
import { AuthContext } from '../context/AuthContext';
import { ThemeContext } from '../context/ThemeContext';
import Post from './Post';
import { formatTimeSince, timeSince } from '../helper/PostHelper';
import pencil from '../assets/pencil.svg';
import { Dialog, DialogContent } from '@mui/material';
import { getImageUrl, LoadingSpinner } from '../helper/StylesHelper';
import { followUser } from '../helper/ProfileHelper';
import useResponsiveWidth from '../hooks/useResponsiveWidth';
import Portfolio from './Portfolio';
/**
 * @typedef {import('../helper/PostHelper').PostObject} PostObject
 * @typedef {import('../helper/ProfileHelper').UserObject} UserObject
 */

export default function ProfileV2() {

  useResponsiveWidth();

  const [activeTab, setActiveTab] = useState('posts');

  /** @type {[PostObject[], Function]} */
  const [userPosts, setUserPosts] = useState([]);


  //userId is the state of the user that is being viewed
  const [userId, setUserId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [postsLoading, setPostsLoading] = useState(true);
  const navigate = useNavigate();
  const { username } = useParams();
  const { userId: currentUserId, userToken, setUsername, setName, setProfilePicture, signOut } = useContext(AuthContext);
  const { dk } = useContext(ThemeContext);

  /** @type {[UserObject, Function]} */
  const [currentUser, setCurrentUser] = useState(null);

  /** @type {[UserObject, Function]} */
  const [viewingUser, setViewingUser] = useState(null);

  const [editMode, setEditMode] = useState(false);
  const [isFollowing, setIsFollowing] = useState(false);
  const [editUsername, setEditUsername] = useState('');
  const [editName, setEditName] = useState('');
  const [editBio, setEditBio] = useState('');
  const [selectedTime, setSelectedTime] = useState('All time');
  const [selectedSort, setSelectedSort] = useState('Most recent');
  const [editSocial, setEditSocial] = useState({
    instagram: '',
    youtube: '',
    discord: '',
    tiktok: '',
    substack: '',
    github: '',
    website: '',
  });
  const [editProfilePic, setEditProfilePic] = useState('');
  const [editBannerPic, setEditBannerPic] = useState('');
  const [editProfilePicBlob, setEditProfilePicBlob] = useState(null); 
  const [editBannerPicBlob, setEditBannerPicBlob] = useState(null);

  // Cropper state for profile picture
  const [showPfpCropper, setShowPfpCropper] = useState(false);
  const [pfpCrop, setPfpCrop] = useState({ x: 0, y: 0 });
  const [pfpZoom, setPfpZoom] = useState(1);
  const [pfpCroppedAreaPixels, setPfpCroppedAreaPixels] = useState(null);
  const [pfpImageSrc, setPfpImageSrc] = useState(null);

  // Cropper state for banner picture
  const [showBannerCropper, setShowBannerCropper] = useState(false);
  const [bannerCrop, setBannerCrop] = useState({ x: 0, y: 0 });
  const [bannerZoom, setBannerZoom] = useState(1);
  const [bannerCroppedAreaPixels, setBannerCroppedAreaPixels] = useState(null);
  const [bannerImageSrc, setBannerImageSrc] = useState(null);
  const [showLinks, setShowLinks] = useState(false);

  // Fixed aspect ratio for banner image
  const bannerAspectRatio = 6 / 1; 

  // Pagination
  const viewMoreRef = useRef(null); // Ref for the "View more" button
  const page = useRef(0);
  const [hasMorePosts, setHasMorePosts] = useState(true);


  const handlePfpChange = async (e) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      const maxSize = 3 * 1024 * 1024; // 3 MB

      if (file.size > maxSize) {
        alert('File size exceeds 3 MB. Please upload a smaller file.');
        return;
      }
      const validImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp', 'image/svg+xml'];
      if (validImageTypes.includes(file.type)) {
        const reader = new FileReader();
        reader.onload = () => {
          setPfpImageSrc(reader.result);
          setShowPfpCropper(true);
        };
        reader.readAsDataURL(file);
      } else {
        alert('Please upload a valid image file (JPEG, PNG, GIF, WEBP, or SVG).');
      }
    } else {
      console.error('No file selected or invalid file');
    }
  };

  const handleBannerPicChange = async (e) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      const maxSize = 3 * 1024 * 1024; // 3 MB

      if (file.size > maxSize) {
        alert('File size exceeds 3 MB. Please upload a smaller file.');
        return;
      }
      const validImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp', 'image/svg+xml'];
      if (validImageTypes.includes(file.type)) {
        const reader = new FileReader();
        reader.onload = () => {
          setBannerImageSrc(reader.result);
          setShowBannerCropper(true);
        };
        reader.readAsDataURL(file);
      } else {
        alert('Please upload a valid image file (JPEG, PNG, GIF, WEBP, or SVG).');
      }
    } else {
      console.error('No file selected or invalid file');
    }
  };


  const handlePfpCropComplete = async () => {
    try {
      const croppedImageBlob = await getCroppedImg(pfpImageSrc, pfpCroppedAreaPixels);
      const croppedImageUrl = URL.createObjectURL(croppedImageBlob);
      setEditProfilePic(croppedImageUrl); 
      setEditProfilePicBlob(croppedImageBlob);
      setShowPfpCropper(false);
    } catch (error) {
      console.error('Error cropping image', error);
    }
  };

  const handleBannerCropComplete = async () => {
    try {
      const croppedImageBlob = await getCroppedImg(bannerImageSrc, bannerCroppedAreaPixels);
      const croppedImageUrl = URL.createObjectURL(croppedImageBlob); 
      setEditBannerPic(croppedImageUrl); 
      setEditBannerPicBlob(croppedImageBlob);
      setShowBannerCropper(false);
    } catch (error) {
      console.error('Error cropping image', error);
    }
  };

  function getCroppedImg(imageSrc, croppedAreaPixels) {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.src = imageSrc;
      image.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = croppedAreaPixels.width;
        canvas.height = croppedAreaPixels.height;
        const ctx = canvas.getContext('2d');
  
        ctx.drawImage(
          image,
          croppedAreaPixels.x,
          croppedAreaPixels.y,
          croppedAreaPixels.width,
          croppedAreaPixels.height,
          0,
          0,
          croppedAreaPixels.width,
          croppedAreaPixels.height
        );
  
        canvas.toBlob(
          (blob) => {
            resolve(blob);
          },
          'image/jpeg',
          0.8
        );
      };
      image.onerror = (error) => reject(error);
    });
  }

  const handleSocialChange = (key, value) => {
    setEditSocial({ ...editSocial, [key]: value });
  };

  const handleSave = async () => {
    try {
      if (!editUsername) {
        alert('Username is required');
        return;
      }
      if (!editName) {
        alert('Name is required');
        return;
      }
      if (editBio.length > 80) {
        alert('Bio must be less than 80 characters');
        return;
      }

      const formData = new FormData();
      formData.append('userId', currentUserId);
      formData.append('username', editUsername);
      formData.append('name', editName);
      formData.append('bio', editBio);
      formData.append('social', JSON.stringify(editSocial));
      
      if (editProfilePicBlob) {
        const profilePicExtension = editProfilePicBlob.type.split('/')[1];
        formData.append('profilePicture', editProfilePicBlob, `profile.${profilePicExtension}`);
      }
      
      if (editBannerPicBlob) {
        const bannerPicExtension = editBannerPicBlob.type.split('/')[1]; 
        formData.append('bannerPicture', editBannerPicBlob, `banner.${bannerPicExtension}`);
      }

      await axios.put(`${config.backendUrl}/api/auth/edit-profile`, formData, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });

      setEditMode(false);
      navigate(`/profile/${editUsername}`);
      alert('Profile updated successfully');
    } catch (error) {
      alert(error.response?.data?.message || 'Error saving profile');
      console.error('Error saving profile:', error);
    }
  };


  const handleBookmarkClick = async (postId, isBookmarked) => {
    try {
      const url = `${config.backendUrl}/api/bookmarks/${postId}`;
      const method = isBookmarked ? 'delete' : 'post';
      await axios({ method, url, headers: { Authorization: `Bearer ${userToken}` } });
    } catch (error) {
      console.error('Error updating bookmark:', error);
    }
  };

  const handleEditClick = () => {
    setEditMode(true);
    setEditUsername(viewingUser?.username || '');
    setEditName(viewingUser?.name || '');
    setEditBio(viewingUser?.bio || '');
    setEditSocial(viewingUser?.social || {});
    setEditProfilePic(getImageUrl(viewingUser?.profilePicture) || '');
    setEditBannerPic(getImageUrl(viewingUser?.bannerPicture) || '');
    setEditProfilePicBlob(null);
    setEditBannerPicBlob(null);
  };

  // Fetch user posts with pagination
  const fetchUserPosts = async (reset = false) => {
    setPostsLoading(true);
    if (reset) {
      page.current = 0;
      setUserPosts([]);
      setHasMorePosts(true);
    }
    

    try {
      const response = await axios.get(
        `${config.backendUrl}/api/posts/user/${username}/posts`,
        {
          headers: { Authorization: `Bearer ${userToken}` },
          params: {
            page: page.current,
            limit: 5,
            selectedTime,
            selectedSort,
          },
        }
      );

      const { posts: fetchedPosts, userIdPoster } = response.data;
      setUserId(userIdPoster);

      if (page.current === 0) {
        setUserPosts(fetchedPosts);
      } else {
        setUserPosts((prevPosts) => [...prevPosts, ...fetchedPosts]);
      }

      setHasMorePosts(fetchedPosts.length === 5);
    } catch (error) {
      console.error('Error fetching user posts:', error);
    }

    setPostsLoading(false);
  };



  // Fetch user data
  useEffect(() => {
    if (editMode) return;
    const fetchUserData = async () => {
      if (!currentUserId || !username) return;

      try {
        // Fetch user data for viewingUser and currentUser
        const [userResponse, currentUserResponse] = await Promise.all([
          axios.get(`${config.backendUrl}/api/auth/user/username/${username}`, {headers: { Authorization: `Bearer ${userToken}` }}),
          axios.get(`${config.backendUrl}/api/auth/user/id/${currentUserId}`, {headers: { Authorization: `Bearer ${userToken}` }}),
        ]);

        setViewingUser(userResponse.data);
        setCurrentUser(currentUserResponse.data);

        const userIdPoster = userResponse.data._id;
        setUserId(userIdPoster);

        if (currentUserId !== userIdPoster) {
          // Check if the current user follows the viewing user
          const followResponse = await axios.post(
            `${config.backendUrl}/api/auth/isFollowing`,
            { currentUserId, viewingUserId: userIdPoster },
            { headers: { Authorization: `Bearer ${userToken}` } }
          );
          setIsFollowing(followResponse.data.isFollowing);



        } else {
          // If the user is viewing their own profile
          setUsername(currentUserResponse.data.username);
          setName(currentUserResponse.data.name);
          setProfilePicture(currentUserResponse.data.profilePicture);
          localStorage.setItem('username', currentUserResponse.data.username);
          localStorage.setItem('name', currentUserResponse.data.name);
          localStorage.setItem('profilePicture', currentUserResponse.data.profilePicture);

          setIsFollowing(false);
        }

        // Fetch initial posts
        await fetchUserPosts(true);

      } catch (error) {
        console.error('Error fetching data:', error);
        setViewingUser(null);
      } finally {
        setLoading(false);
      }
    };

    setLoading(true);
    fetchUserData();
    //eslint-disable-next-line
  }, [username, currentUserId, userToken, editMode, setName, setProfilePicture, setUsername]);

  // Infinite scroll using IntersectionObserver
  useEffect(() => {
    const currentRef = viewMoreRef.current;

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasMorePosts && !loading) {
          page.current += 1;
          fetchUserPosts();
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 1.0,
      }
    );

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
    //eslint-disable-next-line
  }, [hasMorePosts, loading, viewingUser]);

  // Reset pagination when filters change
  useEffect(() => {
    if (activeTab === 'posts') {
      fetchUserPosts(true);
    } else if (activeTab === 'portfolio') {
      // navigate(`/portfolio/${username}`,{ state: { fromProfile: true }});
    }
    //eslint-disable-next-line
  }, [selectedTime, selectedSort, activeTab]);




  const renderContent = () => {
    if (activeTab === 'posts') {
      const filteredPosts = userPosts;
  
      return (
        <div className='ProfileContentWrapper'>
              <div className='ProfilePortfolioContent'>
              <h2 className='ProfileContentHeader'
              onClick={()=>    navigate(`/portfolio/${username}`,{ state: { fromProfile: true }})}
              
              >
                    Portfolio
                </h2>
            <Portfolio inProfile={true}/>
            </div>
            <div className='ProfileMainContent'>
                <h2 className='ProfileContentHeader'>
                    Posts
                </h2>
            <div className="pr-LowerTopBarProfile" style = {{marginLeft: 0}}>
                          <select
                            className="pr-LowerTopBarButtonProfile bold"
                            value={selectedTime}
                            onChange={(e) => setSelectedTime(e.target.value)}
                          >
                            <option value="All time">All time</option>
                            <option value="Today">Today</option>
                            <option value="7 days">Last 7 days</option>
                            <option value="1 month">Last 1 month</option>
                          </select>
                          <select
                            className="pr-LowerTopBarButtonProfile bold"
                            value={selectedSort}
                            onChange={(e) => setSelectedSort(e.target.value)}
                          >
                            <option value="Most recent">Most recent</option>
                            <option value="Most popular">Most popular</option>
                            <option value="Most commented">Most commented</option>
                          </select>
                        </div>

       
          {filteredPosts.length > 0 ? (
            filteredPosts.map((post) => (
              <Post
                key={post._id}
                postId={post._id}
                profilePic={post.author.profilePicture}
                author={post.author.name}
                verified={post.author.isUserVerified}
                username={post.author.username}
                time={formatTimeSince(timeSince(post.timePosted))}
                content={post.content}
                tradeDetails={post.tradeDetails}
                title={post.title}
                sentiment={post.sentiment}
                type={post.type}
                categories={post.categories}
                bookmarked={post.isBookmarked}
                onBookmarkClick={() =>
                  handleBookmarkClick(post._id, post.isBookmarked)
                }
                isQuotePost={post.isQuotePost}
                quotePostId={post.quotePostId}
                isCopyRepost={false}
                images={post.images}
                isUpvoted={post.isUpvoted}
                isDownvoted={post.isDownvoted}
                isAdmin={currentUser?.isAdmin}
                caption={post.caption}
                refreshFeed={() =>
                  setUserPosts(userPosts.filter((p) => p._id !== post._id))
                }
                isAuthorFollowed={
                  isFollowing || post.author._id === currentUserId
                }
                authorId={post.author._id}
                refreshFollowingStatus={() => setIsFollowing(!isFollowing)}
                upvoteCountParam={post.upvoteCount}
                downvoteCountParam={post.downvoteCount}
                commentCountParam={post.commentCount}
                repostCountParam={post.repostCount}
                isCopyRepostedParam={post.isCopyReposted}
                taggedAssets={post.taggedAssets}
                transactionId={post.transactionId}
              />
            ))
          ) : (
            !postsLoading && <div>No posts available.</div>
          )}
  
          {postsLoading && (
            <div style={{ textAlign: 'center', margin: '1rem 0' }}>
              <LoadingSpinner />
            </div>
          )}
  
          {hasMorePosts && !postsLoading && (
            <button
              ref={viewMoreRef}
              style={{ margin: '0 auto', marginTop: '1rem' }}
              className="cp-AddElementButton"
              onClick={() => {
                page.current += 1;
                fetchUserPosts();
              }}
            >
              View more
            </button>
          )}
          {!hasMorePosts && !postsLoading && filteredPosts.length > 0 && (
            <p style={{ textAlign: 'center', margin: '1rem 0' }}>
              No more posts to show
            </p>
          )}

            </div>
          
        </div>
      );
    }

   
  
    return null;
  };


  return (
    <div className="pr-Profile">
  
      {!loading ? (
        viewingUser ? (
            <>
              <div
                className="pr-banner-image-wrapper"
                style={{ cursor: editMode ? 'pointer' : '' }}
                onClick={() => editMode && document.getElementById('bannerPicInput').click()}
              >
                <img
                  src={editBannerPic || getImageUrl(viewingUser?.bannerPicture)} 
                  alt="Profile Header"
                  className="pr-banner-image"
                />
              </div>
              <input
                type="file"
                id="bannerPicInput"
                style={{ display: 'none' }}
                onChange={handleBannerPicChange}
                disabled={!editMode}
              />
              <div className="pr-profile-main-content" style={{ display: 'flex', flexDirection: 'column' }}>
                <div className="pr-profile-sidebar">
                  <div className="pr-profile-info">
                    <div
                      style={{ cursor: editMode ? 'pointer' : '' }}
                      className="pr-profile-picture-wrapper"
                      onClick={() => editMode && document.getElementById('profilePicInput').click()}
                    >
                      <img
                        src={editProfilePic ||  getImageUrl(viewingUser?.profilePicture)}
                        alt="Profile"
                        className="pr-profile-picture"
                      />
                      <input
                        type="file"
                        id="profilePicInput"
                        style={{ display: 'none' }}
                        onChange={handlePfpChange}
                        disabled={!editMode}
                      />

                      {editMode && (
                        <div className="pr-pencil-container">
                          <img src={pencil} alt="Edit" className="pr-pencil-icon" />
                        </div>
                      )}
                    </div>

                    <div className="pr-main-profile-info-wrapper">
                      <div className='pr-head-wrapper'>
                      <div>
                      <h2 className="pr-name-title bold">{editName || viewingUser?.name}</h2>
                      <span className="pr-username-title medium" style={{ textAlign: 'left' }}>@{editUsername || username}</span>
                      </div>

                      <div className="pr-actions">
                        {currentUserId !== userId ? (
                          <button className="CreatePostFeedButton bold" onClick={()=> followUser(currentUserId, userId, userToken, setIsFollowing)}>
                            {isFollowing ? 'Unfollow' : 'Follow'}
                          </button>
                        ) : !editMode ? (
                          <>
                            <button className="CreatePostFeedButton bold" onClick={handleEditClick}>
                              Edit
                            </button>
                            <button className="CreatePostFeedButton bold" onClick={()=> {signOut(); navigate('/signin')}}>
                              Log out
                            </button>
                          </>
                        ) : (
                          <>
                            <button className="CreatePostFeedButton greenButton bold" onClick={handleSave}>
                              Save
                            </button>
                            <button className="CreatePostFeedButton bold" onClick={signOut}>
                              Log out
                            </button>
                          </>
                        )}
                      </div>

                      </div>
                  {  viewingUser?.bio &&  <><span className="pr-stat-label bold">Bio</span><p className="pr-bio">{viewingUser?.bio}</p></>}
                      <div className="pr-stats">
                        <div className="pr-stat-label-wrapper">
                      <span className="pr-stat-label bold">Followers</span>
                       <span className="bold">{viewingUser?.followCount}</span> 
                       </div>
                       <div className="pr-stat-label-wrapper">
                        <span className="pr-stat-label bold">Following</span>
                        <span className="bold">{viewingUser?.followingCount}</span>
                        </div>

                        <div className="pr-social-media-icons">
                        {Object.values(viewingUser?.social || {}).some((link) => link) && (
                          <button className='CreatePostFeedButton' onClick={() => setShowLinks((prev) => !prev)}>
                            Links
                          </button>
                        )}
                     {showLinks &&  (
                        <>
                          {viewingUser?.social.instagram && (
                            <button
                              className="CreatePostFeedButton"
                              onClick={() => window.open(viewingUser.social.instagram, '_blank')}
                            >
                              Instagram
                            </button>
                          )}

                          {viewingUser?.social.twitter && (
                            <button
                              className="CreatePostFeedButton"
                              onClick={() => window.open(viewingUser.social.twitter, '_blank')}
                            >
                              Twitter
                            </button>
                          )}

                          {viewingUser?.social.tiktok && (
                            <button
                              className="CreatePostFeedButton"
                              onClick={() => window.open(viewingUser.social.tiktok, '_blank')}
                            >
                              TikTok
                            </button>
                          )}

                          {viewingUser?.social.discord && (
                            <button
                              className="CreatePostFeedButton"
                              onClick={() => window.open(viewingUser.social.discord, '_blank')}
                            >
                              Discord
                            </button>
                          )}

                          {viewingUser?.social.youtube && (
                            <button
                              className="CreatePostFeedButton"
                              onClick={() => window.open(viewingUser.social.youtube, '_blank')}
                            >
                              YouTube
                            </button>
                          )}

                          {viewingUser?.social.website && (
                            <button
                              className="CreatePostFeedButton"
                              onClick={() => window.open(viewingUser.social.website, '_blank')}
                            >
                              Website
                            </button>
                          )}

                          {viewingUser?.social.github && (
                            <button
                              className="CreatePostFeedButton"
                              onClick={() => window.open(viewingUser.social.github, '_blank')}
                            >
                              GitHub
                            </button>
                          )}

                          {viewingUser?.social.substack && (
                            <button
                              className="CreatePostFeedButton"
                              onClick={() => window.open(viewingUser.social.substack, '_blank')}
                            >
                              Substack
                            </button>
                          )}
                        </>
                      )}
                      </div>
                      </div>
                    
                    </div>
                  </div>
                </div>

                <div className="pr-main-profile-content">
                  {!editMode ? (
                    <>
                     
                   
                      <div className="pr-feed-content">{renderContent()}</div>
                    </>
                  ) : (
                    <div className="pr-MainEditContainer">
                <div className="pr-EditProfileContainer">
                  <h2 className="pr-EditTitle">Edit Profile</h2>
                  <hr className="pr-hr" />

                  <div className="pr-MainEditWrapper">
                    {/* Username and Name on the same line */}
                    <div className="pr-NameUsernameContainer">
                      <div className="pr-UsernameEditContainer">
                        <label className="pr-FieldName bold">Username</label>
                        <input
                          type="text"
                          className="pr-EditInput"
                          value={editUsername}
                          onChange={(e) => setEditUsername(e.target.value)}
                        />
                        <div className="pr-GuideText">
                          Usernames can only contain letters, numbers, underscores, and periods. Changing your username will also change your profile link.
                        </div>
                      </div>

                      <div className="pr-NameEditContainer">
                        <label className="pr-FieldName bold">Name</label>
                        <input
                          type="text"
                          className="pr-EditInput"
                          value={editName}
                          onChange={(e) => setEditName(e.target.value)}
                        />
                        <div className="pr-GuideText">Your nickname can only be changed once every 7 days.</div>
                      </div>
                    </div>

                    {/* Bio Field */}
                    <div className="pr-BioEditContainer">
                      <label className="pr-FieldName bold">Bio</label>
                      <textarea
                        className="pr-EditTextarea"
                        value={editBio}
                        onChange={(e) => setEditBio(e.target.value)}
                      />
                      <div className="pr-GuideText">{editBio.length}/80</div>
                    </div>

                    {/* Website Field */}
                    <div className="pr-WebsiteEditContainer">
                      <label className="pr-FieldName bold">Website</label>
                      <input
                        type="text"
                        className="pr-EditInput"
                        value={editSocial.website}
                        onChange={(e) => handleSocialChange('website', e.target.value)}
                        placeholder="https://"
                      />
                    </div>

                    {/* Social Fields in Two Columns */}
                    <div className="pr-SocialEditContainer">
                      <label className="pr-FieldName bold" style={{ alignSelf: 'flex-start', marginTop: '1rem' }}>
                        Social
                      </label>
                      <div className="pr-SocialInputsContainer">
                        <input
                          type="text"
                          className="pr-EditInput"
                          placeholder="Instagram"
                          value={editSocial.instagram}
                          onChange={(e) => handleSocialChange('instagram', e.target.value)}
                        />
                        <input
                          type="text"
                          className="pr-EditInput"
                          placeholder="Twitter"
                          value={editSocial.twitter}
                          onChange={(e) => handleSocialChange('twitter', e.target.value)}
                        />
                        <input
                          type="text"
                          className="pr-EditInput"
                          placeholder="YouTube"
                          value={editSocial.youtube}
                          onChange={(e) => handleSocialChange('youtube', e.target.value)}
                        />
                        <input
                          type="text"
                          className="pr-EditInput"
                          placeholder="Discord"
                          value={editSocial.discord}
                          onChange={(e) => handleSocialChange('discord', e.target.value)}
                        />
                        <input
                          type="text"
                          className="pr-EditInput"
                          placeholder="TikTok"
                          value={editSocial.tiktok}
                          onChange={(e) => handleSocialChange('tiktok', e.target.value)}
                        />
                        <input
                          type="text"
                          className="pr-EditInput"
                          placeholder="Substack"
                          value={editSocial.substack}
                          onChange={(e) => handleSocialChange('substack', e.target.value)}
                        />
                        <input
                          type="text"
                          className="pr-EditInput"
                          placeholder="GitHub"
                          value={editSocial.github}
                          onChange={(e) => handleSocialChange('github', e.target.value)}
                        />
                      </div>
                    </div>

                    {/* Save Button */}
                    <div className="pr-SaveButtonContainer">
                      <button className="CreatePostFeedButton bold" onClick={handleSave}>
                        Save
                      </button>
                    </div>
                  </div>
                </div>
</div>

                  )}
                </div>
              </div>

              {/* Profile Picture Cropper */}
              <Dialog 
              sx={{ 
                '& .MuiDialog-paper': {
                  padding: '2rem',
                  borderRadius: '1rem',
                  backgroundColor: dk() ? '#030902' : '#fff',
                }
              }}
              open={showPfpCropper} onClose={() => setShowPfpCropper(false)} maxWidth="sm" fullWidth>
                <DialogContent>
                  <div className="crop-container">
                    <Cropper
                      image={pfpImageSrc}
                      crop={pfpCrop}
                      zoom={pfpZoom}
                      aspect={1}
                      cropShape="round"
                      showGrid={false}
                      onCropChange={setPfpCrop}
                      onZoomChange={setPfpZoom}
                      onCropComplete={(croppedArea, croppedAreaPixels) => {
                        setPfpCroppedAreaPixels(croppedAreaPixels);
                      }}
                    />
                  </div>
                  <div className="crop-actions">
                    <span>Scale: {pfpZoom}x</span>
                    <input
                      type="range"
                      min={1}
                      max={3}
                      step={0.1}
                      value={pfpZoom}
                      onChange={(e) => setPfpZoom(e.target.value)}
                    />
                    <div className="crop-buttons">
                      <button className="pr-follow-btn bold" onClick={handlePfpCropComplete}>
                        Save
                      </button>
                      <button className="pr-follow-btn bold" onClick={() => setShowPfpCropper(false)}>
                        Cancel
                      </button>
                    </div>
                  </div>
                </DialogContent>
              </Dialog>

              {/* Banner Picture Cropper */}
              <Dialog 
              sx={{ 
                '& .MuiDialog-paper': {
                  padding: '2rem',
                  borderRadius: '1rem',
                  backgroundColor: dk() ? '#030902' : '#fff',
                }
              }}
              
              open={showBannerCropper} onClose={() => setShowBannerCropper(false)} maxWidth="md" fullWidth>
                <DialogContent>
                  <div className="crop-container">
                    <Cropper
                      image={bannerImageSrc}
                      crop={bannerCrop}
                      zoom={bannerZoom}
                      aspect={bannerAspectRatio}
                      onCropChange={setBannerCrop}
                      onZoomChange={setBannerZoom}
                      showGrid={false}
                      onCropComplete={(croppedArea, croppedAreaPixels) => {
                        setBannerCroppedAreaPixels(croppedAreaPixels);
                      }}
                    />
                  </div>
                  <div className="crop-actions">
                    <span>Scale: {bannerZoom}x</span>
                    <input
                      type="range"
                      min={1}
                      max={3}
                      step={0.1}
                      value={bannerZoom}
                      onChange={(e) => setBannerZoom(e.target.value)}
                    />
                    <div className="crop-buttons">
                      <button className="pr-follow-btn bold" onClick={handleBannerCropComplete}>
                        Save
                      </button>
                      <button className="pr-follow-btn bold" onClick={() => setShowBannerCropper(false)}>
                        Cancel
                      </button>
                    </div>
                  </div>
                </DialogContent>
              </Dialog>
            </>
          ) : (
            <div>User not found or private</div>
          )
      ) : (
        <div className="pr-Profile loading">
         <LoadingSpinner />
        </div>
      )}
    </div>
  );
}